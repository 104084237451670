import { KeyOperationParameters } from "src/modules/stores/databaseKeys/DatabaseKeys";

import { FeatureFlag } from "src/modules/utils/featureFlags";

import { getDatabaseKeyStore } from "src/modules/stores/databaseKeys/DatabaseKeys";
import { getSessionFeatureFlag } from "src/modules/utils/featureFlags";
import { appendDocumentScope, Scope } from "../fauna/secret";

/** Get a database key if on-demand database keys are enabled. */
export const getDatabaseKeyIfEnabled = async ({
  role,
  path,
}: KeyOperationParameters): Promise<string | undefined> => {
  if (!getSessionFeatureFlag(FeatureFlag.ON_DEMAND_DATABASE_KEYS)) {
    return undefined;
  }
  const key = await getDatabaseKeyStore().getOrCreate({ role, path });
  return key;
};

/** Refresh a database key if on-demand database keys are enabled. */
export const getRefreshedDatabaseKeyIfEnabled = async (
  key: string
): Promise<string | undefined> => {
  if (!getSessionFeatureFlag(FeatureFlag.ON_DEMAND_DATABASE_KEYS)) {
    return undefined;
  }
  return await getDatabaseKeyStore().refresh(key);
};

/**
 * A function that consumes a secret and returns a value.
 * @template T the type of the value returned by the function.
 */
interface OnDemandDatabaseKeyConsumer<T> {
  (secret: string): T;
}

/**
 * A function that determines if an error should trigger a retry for a refreshed
 * on-demand database key.
 * @param error the error to check.
 * @returns true if the error should trigger a retry, false otherwise.
 */
interface OnDemandDatabaseKeyRetryCondition {
  (error: unknown): boolean;
}

/**
 * Parameters for the `withOnDemandDatabaseKey` function.
 * @template T the type of the value returned by the function.
 */
type WithOnDemandDatabaseKeyParameters<T = any> = {
  fallback?: string;
  path: string;
  role: string;
  scope?: Scope;
  fn: OnDemandDatabaseKeyConsumer<T>;
  retryOn: OnDemandDatabaseKeyRetryCondition;
};

/**
 * A function that attempts to get a short-lived database key and use it to
 * execute a function. If the key is not found, it will use the fallback secret.
 * If the key is expired, it will attempt to refresh it.
 * @template T the type of the value returned by the function.
 */
export async function withOnDemandDatabaseKey<T = any>({
  fallback,
  path,
  role,
  fn,
  retryOn,
  scope = {},
}: WithOnDemandDatabaseKeyParameters<T>) {
  let onDemandKey = await getDatabaseKeyIfEnabled({ path, role });
  if (onDemandKey !== undefined) {
    // If we're using an on-demand key, we need to append the document scope
    // to the secret if it exists. Roles are already applied when the key is
    // created so we don't need to do anything there.
    onDemandKey = appendDocumentScope(onDemandKey, scope);
  }

  const resolvedSecret = onDemandKey ?? fallback;

  if (!resolvedSecret) {
    throw new Error(
      "Unable to request authentication token and fallback is not set."
    );
  }

  try {
    return await fn(resolvedSecret);
  } catch (e: unknown) {
    // If we're using an on-demand key and it's expired, we should try to refresh it.
    if (retryOn(e) && onDemandKey) {
      const refreshedKey = await getRefreshedDatabaseKeyIfEnabled(onDemandKey);
      const resolvedSecret = refreshedKey ?? fallback;

      if (!resolvedSecret) {
        throw new Error(
          "Unable to refresh authentication token and fallback is not set."
        );
      }

      return fn(resolvedSecret);
    }
    throw e;
  }
}
