/*
 * window.localStorage implementation of our cookie interface
 * this exists to cause minimal change to the code-base
 * while moving from storing session info in cookies
 * to storing it in sessionStorage.
 */
import { Session } from ".";
import * as cookieSession from "./cookie-session";
const { get: getCookieSession } = cookieSession.default;

type SessionCookieContents = {
  data: Session;
  strategy: string;
  provider?: string;
};

const LOCAL_STORAGE_KEY = "session";

const get = (): SessionCookieContents | undefined => {
  let cookieStr = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  let cookie: SessionCookieContents | undefined = cookieStr
    ? JSON.parse(cookieStr)
    : undefined;
  if (cookie) return cookie;
  return getCookieSession();
};

const remove = (): void => {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY);
};

const save = (contents: SessionCookieContents): void => {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(contents));
};

const exists = (): boolean => {
  return !!window.localStorage.getItem(LOCAL_STORAGE_KEY);
};

const localStorageSession = {
  get,
  remove,
  save,
  exists,
  LOCAL_STORAGE_KEY,
};

export default localStorageSession;
