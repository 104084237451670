import env from "src/env";
import { Role } from "modules/api/auth-service/permissions";
import { fetchWithCredentials } from "modules/api/utils/fetch";
import { commonResponseHandler } from "modules/api/auth-service/common";

export type NewInvite = {
  email: string;
  first_name: string;
  last_name: string;
  role: Role;
};

export type Invite = {
  id: string;
  email: string;
  name: string;
  role: Role;
  send_by: string;
};

export const API_URL = `${env.VITE_AUTH_URL}/account/members/invites`;

/**
 * Send an invite to a new member.
 *
 * @param invite - The invite data.
 * @returns The invite that was sent.
 * @throws A FetchError if the request fails.
 */
export const sendInvite = async (invite: NewInvite): Promise<Invite> => {
  const response = await fetchWithCredentials({
    url: API_URL,
    options: {
      method: "POST",
      body: JSON.stringify(invite),
      headers: { "Content-Type": "application/json" },
    },
  });

  return commonResponseHandler<Invite>({
    response,
    defaultErrorMessage: "Failed to send invite",
  });
};

/**
 * Get all invites associated with the current account.
 *
 * @returns All invites.
 * @throws A FetchError if the request fails.
 */
export const getInvites = async (): Promise<Invite[]> => {
  const response = await fetchWithCredentials({
    url: API_URL,
    options: { method: "GET" },
  });

  return commonResponseHandler<Invite[]>({
    response,
    defaultErrorMessage: "Failed to get invites",
  });
};

/**
 * Resend an invite.
 *
 * @param inviteId - The invite ID.
 * @returns The invite that was resent.
 * @throws A FetchError if the request fails.
 */
export const resendInvite = async (inviteId: string): Promise<Invite> => {
  const response = await fetchWithCredentials({
    url: `${API_URL}/${inviteId}`,
    options: { method: "PUT" },
  });

  return commonResponseHandler<Invite>({
    response,
    defaultErrorMessage: "Failed to resend invite",
  });
};

/**
 * Cancel an invite.
 *
 * @param inviteId - The invite ID.
 * @throws A FetchError if the request fails.
 */
export const cancelInvite = async (inviteId: string): Promise<void> => {
  const response = await fetchWithCredentials({
    url: `${API_URL}/${inviteId}`,
    options: { method: "DELETE" },
  });

  return commonResponseHandler({
    response,
    expectResponseData: false,
    defaultErrorMessage: "Failed to cancel invite",
  });
};

/**
 * Get an invite by token.
 *
 * @param token - The invite token.
 * @returns The invite.
 * @throws A FetchError if the request fails.
 */
export const getInviteByToken = async (token: string): Promise<Invite> => {
  const response = await fetchWithCredentials({
    url: `${API_URL}/${token}`,
    options: { method: "GET" },
  });

  return commonResponseHandler<Invite>({
    response,
    defaultErrorMessage: "Failed to get invite by token",
  });
};
