import { refreshSession } from "modules/api/frontdoor/session";

let refreshPromise: Promise<Response> | null = null;

export interface FetchErrorOptions {
  // The HTTP status code.
  status: number;
  // A string (such as "invalid_credentials" which can be used to determine the error type.
  code?: string;
  // A human-readable message.
  message?: string;
  // The metadata from the response.
  metadata?: Record<string, any>;
}

/**
 * A class to encapsulate errors thrown from fetch requests. The intent is to
 * provide a consistent interface for handling request errors in components.
 */
export class FetchError extends Error {
  code: string;
  status: number;
  message: string;
  metadata?: Record<string, any>;

  constructor({
    status,
    code = "unknown_error",
    message = "An unknown error occurred",
    metadata,
  }: FetchErrorOptions) {
    super(message);
    this.code = code;
    this.status = status;
    this.message = message;
    this.metadata = metadata;
  }
}

/**
 * Fetches a resource from the given URL with the given options. If the response is a 401,
 * the session is refreshed and the request is retried. If the refresh fails, the user is
 * redirected to the login page.
 *
 * @param options The function options.
 * @param options.url The URL to fetch.
 * @param options.options The options to pass to the fetch request.
 * @returns The response object.
 * @throws An error if the response cannot be parsed.
 */
export const fetchWithCredentials = async ({
  url,
  options = {},
}: {
  url: string | URL;
  options: RequestInit;
}): Promise<Response> => {
  let response = await fetch(url, { ...options, credentials: "include" });

  // If we have invalid credentials, refresh the session.
  if (response.status === 401) {
    // If the refresh request is not already in flight, kick it off.
    if (refreshPromise === null) {
      refreshPromise = refreshSession()
        .then((res) => {
          // If the refresh fails, redirect to login. This means
          // the refresh token is invalid or expired.
          if (res.status === 401 || res.status === 403) {
            const params = new URLSearchParams({ "invalid-session": "true" });
            if (window.location.pathname)
              params.set("redirect-url", window.location.pathname);
            window.location.href = `/login?${params.toString()}`;
          }
          return res;
        })
        .finally(() => {
          refreshPromise = null;
        });
    }
    // Wait for the refresh promise to resolve.
    await refreshPromise;
    // Retry the original request after refresh.
    response = await fetch(url, { ...options, credentials: "include" });
  }

  return response;
};
