import { BASE_FDS_URL } from "./common";
import { fetchWithCredentials } from "modules/api/utils/fetch";
import { commonResponseHandler } from "modules/api/frontdoor/common";

export type CreateDatabaseKeyInput = {
  /**
   * The name of the role.
   */
  role: string;

  /**
   * The name of the key.
   */
  name?: string;

  /**
   * The path to the database.
   */
  path: string;

  /**
   * The TTL of the key as an ISO 8601 string.
   */
  ttl: string;
};

export type CreateDatabaseKeyOutput = {
  /**
   * The secret of the key.
   */
  secret: string;

  /**
   * The path to the database.
   */
  path: string;

  /**
   * The name of the key.
   */
  name: string;

  /**
   * The name of the role.
   */
  role: string;
};

export const createDatabaseKey = async ({
  role,
  path,
  ttl,
  name,
}: CreateDatabaseKeyInput): Promise<CreateDatabaseKeyOutput> => {
  const url = `${BASE_FDS_URL}/databases/keys`;
  const body = {
    role,
    path,
    ttl,
    name,
  };

  const response = await fetchWithCredentials({
    url,
    options: {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    },
  });

  return commonResponseHandler<CreateDatabaseKeyOutput>({
    response,
    defaultErrorMessage: "Failed to create database key",
  });
};
