import env from "src/env";
import { Session } from ".";
import * as cookies from "../cookies";

type SessionCookieContents = {
  data: Session;
  strategy: string;
  provider?: string;
};

const SESSION_COOKIE_NAME = "session";

const SESSION_COOKIE_CONFIG: cookies.CookieConfig = {
  secure: !(env.VITE_IS_LOCAL == "true"),
  expires: 30, // days
  domain: cookies.COOKIE_DOMAIN,
};

const get = (): SessionCookieContents => cookies.get(SESSION_COOKIE_NAME);

const remove = (): void => {
  cookies.remove(SESSION_COOKIE_NAME);
};

// https://stackoverflow.com/a/51365037
type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
    ? RecursivePartial<T[P]>
    : T[P];
};

const save = (contents: RecursivePartial<SessionCookieContents>): void =>
  cookies.save(SESSION_COOKIE_NAME, contents, SESSION_COOKIE_CONFIG);

const exists = (): boolean => cookies.exists(SESSION_COOKIE_NAME);

const cookieStoredSession = {
  get,
  remove,
  save,
  exists,
  SESSION_COOKIE_NAME,
  SESSION_COOKIE_CONFIG,
};

export default cookieStoredSession;
