import env from "src/env";
import { removeSession } from "modules/session";
import { fetchWithCredentials } from "modules/api/utils/fetch";
import { commonResponseHandler } from "modules/api/auth-service/common";

export const API_URL = `${env.VITE_AUTH_URL}/logout`;

/**
 * Log out the current user.
 *
 * @throws A FetchError if the request fails.
 */
const logout = async (): Promise<void> => {
  const response = await fetchWithCredentials({
    url: API_URL,
    options: { method: "POST" },
  }).finally(() => removeSession());

  return commonResponseHandler({
    response,
    expectResponseData: false,
    defaultErrorMessage: "Failed to log out",
  });
};

export default logout;
