import { bearerToken, BASE_FDS_URL } from "./common";
import OAuthSessionCookie from "modules/cookies/oauthcookie";
import { fetchWithCredentials } from "modules/api/utils/fetch";
import { commonResponseHandler } from "modules/api/frontdoor/common";

export interface AuthorizationCodeRequest {
  status: number;
  resultCode: AuthCodeResultCode;
  body?: {
    request_id: string;
    ttl: string;
    client_name: string;
    requested_scope: string[];
  };
}

export type AuthCodeResultCode =
  | "Success"
  | "OAuth2InvalidClient"
  | "OAuth2GetReqFailed"
  | "OAuth2GetReq403"
  | "OAuth2GetReqBug"
  | "OAuth2DenyReqBug"
  | "OAuth2ConfirmReqBug"
  | "OAuth2DenyReqFailed"
  | "OAuth2ConfirmReqFailed"
  | "OAuth2Confirm403"
  | "OAuth2NoRequestId"
  | "OAuth2RequestIdDne";

export interface AuthCodeBadReq {
  status: number;
  resultCode: AuthCodeResultCode;
  body: {
    reason: string;
  };
}

export interface AuthCodeResponse {
  status: number;
  resultCode: AuthCodeResultCode;
  body: {
    reason: string;
    redirect_url: string;
  };
}

export interface WhoamiResponse {
  account_id: string;
  user_id: string;
  role: string;
}

const retryableStatuses = new Set([500, 503, 429]);

/**
 * Identifies the current user.
 *
 * @param region - The region whose secret should be used to authenticat.
 * @returns The user's information.
 * @throws A FetchError if the request fails.
 */
export const whoami = async (): Promise<WhoamiResponse> => {
  const response = await fetchWithCredentials({
    url: `${BASE_FDS_URL}/whoami`,
    options: { method: "GET" },
  });

  return commonResponseHandler<WhoamiResponse>({ response });
};

export const getAuthorizationCodeRequest = async (
  requestId: string
): Promise<AuthorizationCodeRequest> => {
  const url = `${BASE_FDS_URL}/oauth/authorize/requests/${requestId}`;
  let result: AuthorizationCodeRequest = {
    status: 503,
    resultCode: "OAuth2GetReqFailed",
  };

  const response = await fetch(url, {
    method: "GET",
  }).catch(() => {
    return undefined;
  });
  if (response === undefined) {
    return result;
  }
  const res = {
    status: response.status,
    body: await response.json(),
  };
  result = {
    status: res.status,
    resultCode: getRequestIdCode(res.status),
    body: res.body,
  };

  return result;
};

export const denyAuthorizationCodeRequest = async (requestId: string) => {
  const url = `${BASE_FDS_URL}/oauth/authorize/requests/${requestId}`;
  let result: AuthCodeBadReq | AuthCodeResponse = {
    resultCode: "OAuth2DenyReqFailed",
    status: 503,
    body: { reason: "Request failed unexpectedly" },
  };
  const response = await fetch(url, {
    method: "DELETE",
    headers: { Accept: "application/json" },
  }).catch(() => {
    return undefined;
  });
  if (response !== undefined) {
    const res = {
      status: response.status,
      body: await response.json(),
    };
    const maybeRedirectUrl = res.body.redirect_url;
    const reason = typeof res.body.reason === "string" ? res.body.reason : "";
    if (typeof maybeRedirectUrl === "string") {
      result = {
        status: res.status,
        resultCode: getAuthCodeResultCodeDeny(res.status, maybeRedirectUrl),
        body: {
          redirect_url: maybeRedirectUrl,
          reason,
        },
      };
    } else {
      result = {
        status: res.status,
        resultCode: getAuthCodeResultCodeDeny(res.status, undefined),
        body: {
          reason,
        },
      };
    }
  }
  return result;
};

export const confirmAuthorizationCodeRequest = async (requestId: string) => {
  const url = `${BASE_FDS_URL}/oauth/authorize/requests/${requestId}`;
  let result: AuthCodeBadReq | AuthCodeResponse = {
    resultCode: "OAuth2ConfirmReqFailed",
    status: 503,
    body: { reason: "Request failed unexpectedly" },
  };
  const oauthSessionSecret = OAuthSessionCookie.getOAuthSessionRegionSecret();
  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      Authorization: bearerToken(oauthSessionSecret),
      region_group: "us-std",
    },
  }).catch(() => {
    return undefined;
  });

  if (response !== undefined) {
    const res = {
      status: response.status,
      body: await response.json(),
    };
    const maybeRedirectUrl = res.body.redirect_url;
    const reason = typeof res.body.reason === "string" ? res.body.reason : "";
    if (typeof maybeRedirectUrl === "string") {
      result = {
        status: res.status,
        resultCode: getAuthCodeResultCodeConfirm(res.status, maybeRedirectUrl),
        body: {
          redirect_url: maybeRedirectUrl,
          reason,
        },
      };
    } else {
      result = {
        status: res.status,
        resultCode: getAuthCodeResultCodeConfirm(res.status, undefined),
        body: {
          reason,
        },
      };
    }
  }
  return result;
};

function getAuthCodeResultCodeConfirm(
  status: number,
  redirect_url: string | undefined
): AuthCodeResultCode {
  if (redirect_url !== undefined) {
    if (status === 200) {
      return "Success";
    } else if (status === 404) {
      return "OAuth2RequestIdDne";
    } else if (status === 403) {
      return "OAuth2Confirm403";
    } else {
      return "OAuth2ConfirmReqFailed";
    }
  } else {
    if (retryableStatuses.has(status)) {
      return "OAuth2ConfirmReqFailed";
    } else {
      return "OAuth2ConfirmReqBug";
    }
  }
}

function getAuthCodeResultCodeDeny(
  status: number,
  redirect_url: string | undefined
): AuthCodeResultCode {
  if (redirect_url !== undefined) {
    if (status === 200) {
      return "Success";
    } else if (status === 404) {
      return "OAuth2RequestIdDne";
    } else {
      return "OAuth2DenyReqFailed";
    }
  } else {
    if (retryableStatuses.has(status)) {
      return "OAuth2DenyReqFailed";
    } else {
      return "OAuth2DenyReqBug";
    }
  }
}

function getRequestIdCode(status: number): AuthCodeResultCode {
  if (status === 200) {
    return "Success";
  } else if (status === 404) {
    return "OAuth2RequestIdDne";
  } else if (status === 403) {
    return "OAuth2GetReq403";
  } else if (retryableStatuses.has(status)) {
    return "OAuth2GetReqFailed";
  } else {
    return "OAuth2GetReqBug";
  }
}
