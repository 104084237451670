import localStorageSession from "./local-storage-session";
import cookieSession from "./cookie-session";
import { mutate } from "swr";
import { Region } from "../region";
import {
  Limits,
  PermissionDomain,
  PermissionLevel,
} from "modules/api/auth-service/permissions";
import { RegionInfo } from "modules/region/config";
import type { FeatureFlags } from "modules/api/auth-service/authz";
import { getDatabaseKeyStore } from "../stores/databaseKeys/DatabaseKeys";

export type OAuthProvider = "github" | "netlify";

export type SessionRegion = {
  regionPrefix: string;
  regionName: string;
  url: string;
  graphqlUrl: string;
  secret: string;
};

export type ViewControls = {
  [service in PermissionDomain]?: Array<PermissionLevel>;
};

export type Session = {
  id: string;
  secret: string;
  user: {
    id: string;
    name: string;
    email: string;
    role: string;
    otp_enabled: boolean;
    pending_email: string;
    // storing as a string as its only used by pendo integration currently
    // if you need full date functionality update the login strategies to
    // convert this to a date and update the pendo integration to convert
    // the date to a ISO 8601 string
    created_at: string; // ISO 8601 date string
    planSynced: boolean;
    plan: string;
    plan_display_name: string;
    plan_monthly_price: number;
    is_plan_deprecated: boolean;
    limits: Limits;
    eligible_for_trial: boolean;
    previous_plan: string | null;
  };
  view_controls: ViewControls;
  feature_flags: FeatureFlags;
  account: {
    account_id: string;
    company_name?: string;
    legacy_account: boolean;
  };
  regionGroups: Record<string, SessionRegion>;
  userRegionGroups: Record<string, RegionInfo>;
  signUpMethod: "email_and_password" | "oauth";
  oauthProvider?: OAuthProvider;
  planUpgrade?: {
    status: "pending" | "complete";
    fromPlan: string;
    toPlan: string;
  };
  accountNotifications?: {
    invoiceNeedsAction?: {
      lastNotification: string; // iso string
    };
  };
};

export class NoSessionError extends Error {}

export const hasLocalSession = () => {
  return localStorageSession.exists();
};

export const getSession = (): Session | undefined => {
  const cookie = localStorageSession.get();
  if (!cookie) return undefined;
  return { ...cookie.data };
};

export const getSessionProperty = <T>(path: string[]): T | undefined => {
  const session = getSession();
  let value: any = session;
  for (const p of path) {
    value = value?.[p];
  }
  return value;
};

export const createSession = (session: Session) => {
  // Clear region data and old session data
  Region.clear();
  if (localStorageSession.exists() || cookieSession.exists()) removeSession();
  // Set the new session cookie
  localStorageSession.save({
    data: session,
    // Set these so they may be used by the v4 dashboard
    strategy: session.signUpMethod,
    provider: session.oauthProvider,
  });

  // these fields are used all over the UI; they all look
  // in the real session cookie for them. to move them out
  // of there, we'd have to re-structure all of the login /
  // session behavior.
  cookieSession.save({
    data: {
      id: session.id,
      secret: session.secret,
      regionGroups: session.regionGroups,
      userRegionGroups: session.userRegionGroups,
      user: {
        id: session.user.id,
        role: session.user.role,
      },
      account: {
        account_id: session.account.account_id,
      },
      feature_flags: session.feature_flags,
      view_controls: session.view_controls,
    },
  });

  // Clear SWR cache by marking everything stale
  mutate(() => true, Promise.resolve(undefined));
};

/**
 * Updates the session data stored in the session cookie in the case
 * of receiving updated user identity data from the server. Keeps the
 * session data in sync with backend without requiring a full login.
 * @param data attributes to update on the session object
 */
export const updateSessionData = (data: Partial<Session>) => {
  const cookie = localStorageSession.get();
  if (!cookie) throw new NoSessionError();
  localStorageSession.save({
    ...cookie,
    data: {
      ...cookie.data,
      ...data,
    },
  });
  // Update region data
  Region.rebuildAllRegions();
};

/**
 * Removes a session attribute. Must be an attribute that is not required.
 * @param key the key of the attribute to remove
 */
export const removeSessionAttribute = (
  key: "planUpgrade" | "accountNotifications"
) => {
  const cookie = localStorageSession.get();
  if (!cookie) throw new NoSessionError();
  const data = { ...cookie.data };
  delete data[key];
  localStorageSession.save({
    ...cookie,
    data,
  });
};

export const removeSession = () => {
  // This is a short-term solution to resolve a data persistence bug.
  // A better solution will persist the webshell state securely on customer account.
  // https://faunadb.atlassian.net/browse/FE-1394
  const webshellDataKey = Object.keys({ ...window.localStorage }).find((key) =>
    key.includes("webshellState")
  );
  webshellDataKey && window.localStorage.removeItem(webshellDataKey);

  const ffDataKey = Object.keys({ ...window.localStorage }).find((key) =>
    key.includes("features")
  );
  ffDataKey && window.localStorage.removeItem(ffDataKey);

  // Clear the database keys for the current session user.
  getDatabaseKeyStore().clear();

  localStorageSession.remove();
  cookieSession.remove();

  // Clear region data
  Region.clear();

  // Clear SWR cache by marking everything stale
  mutate(() => true, Promise.resolve(undefined));
};
