import { useContext } from "react";
import { getSession } from "modules/session";
import { UserIdentityContext } from "modules/context/UserIdentityContext";

// Define server-based feature flags here
export enum FeatureFlag {
  GLOBAL_NAV = "global_nav",
  SCHEMA_EDITOR = "schema_editor",
  ACCOUNT_SETTINGS = "account_settings",
  ROLE_ACCESS_PROVIDER_FSL = "role_access_fsl",
  V10_LAUNCHED = "v10_launched",
  ZENDESK_WIDGET = "zendesk_widget",
  // without this flag set we always call the schema update APIs
  // with force=true.  This means that if we are looking at a stale
  // version of the schema in the editor we will clobber what is currently
  // there.  When this flag is enabled, we don't include the force flag
  // so updates fail if the version we are showing the user is stale.
  SCHEMA_VERSION = "schema_version",
  BACKUP_RESTORE = "backup_restore",
  EMAIL_CHANGE = "email_change_verification",
  NEW_ROLES_PAGE = "new_roles_page",
  NEW_DEMO_DATA = "new_demo_data",
  SHOW_REALTIME_LOG_SPEND = "show_realtime_log_spend",
  OAUTH_APPS_NAV = "oauth_apps_nav",
  MONITOR_AUTHENTICATION_TYPES = "monitor_authentication_types",
  ON_DEMAND_DATABASE_KEYS = "on_demand_database_keys",
  PERFORMANCE_HINTS = "performance_hints",
  ONBOARDING_TILES = "onboarding_tiles",
}

export type Features = { [key: string]: string };

export const useServerFeatureFlag = (featureName: FeatureFlag): boolean => {
  const identity = useContext(UserIdentityContext);
  return !!identity.feature_flags[featureName];
};

export const getSessionFeatureFlag = (featureName: FeatureFlag): boolean => {
  return getSession()?.feature_flags[featureName] === true;
};
